// This file is generated by compile-translations.js script
// prettier-ignore
module.exports = [
  {
    "languageCode": "af_ZA",
    "languageName": "Afrikaans",
    "languageNativeName": "Afrikaans",
    "translationRatio": 0.04933148916551411
  },
  {
    "languageCode": "ar_SA",
    "languageName": "Arabic",
    "languageNativeName": "اَلْعَرَبِيَّةُ",
    "translationRatio": 0.9677270631627478
  },
  {
    "languageCode": "az_AZ",
    "languageName": "Azerbaijani",
    "languageNativeName": "azərbaycan dili",
    "translationRatio": 0.039649608114338375
  },
  {
    "languageCode": "bg_BG",
    "languageName": "Bulgarian",
    "languageNativeName": "български език",
    "translationRatio": 0.08609958506224069
  },
  {
    "languageCode": "bn_BD",
    "languageName": "Bengali",
    "languageNativeName": "বাংলা",
    "translationRatio": 0.055209774089442165
  },
  {
    "languageCode": "br_FR",
    "languageName": "Breton",
    "languageNativeName": "brezhoneg",
    "translationRatio": 0.0265099124020286
  },
  {
    "languageCode": "ca_ES",
    "languageName": "Catalan",
    "languageNativeName": "Català",
    "translationRatio": 0.24481327800829877
  },
  {
    "languageCode": "cs_CZ",
    "languageName": "Czech",
    "languageNativeName": "čeština",
    "translationRatio": 0.27531390392811883
  },
  {
    "languageCode": "da_DK",
    "languageName": "Danish",
    "languageNativeName": "Dansk",
    "translationRatio": 0.05863379794954493
  },
  {
    "languageCode": "de_DE",
    "languageName": "German",
    "languageNativeName": "Deutsch",
    "translationRatio": 0.7309065775832277
  },
  {
    "languageCode": "el_GR",
    "languageName": "Greek",
    "languageNativeName": "Ελληνικά",
    "translationRatio": 0.20688860730330605
  },
  {
    "languageCode": "en",
    "languageName": "English",
    "languageNativeName": "English",
    "translationRatio": 0.06952841596130588
  },
  {
    "languageCode": "eo_UY",
    "languageName": "Esperanto",
    "languageNativeName": "Esperanto",
    "translationRatio": 0.21840801750950356
  },
  {
    "languageCode": "es_ES",
    "languageName": "Spanish",
    "languageNativeName": "Español",
    "translationRatio": 0.9706255039741966
  },
  {
    "languageCode": "fa_IR",
    "languageName": "Persian",
    "languageNativeName": "فارسی",
    "translationRatio": 0.21495219444764424
  },
  {
    "languageCode": "fi_FI",
    "languageName": "Finnish",
    "languageNativeName": "suomi",
    "translationRatio": 0.22278539338785852
  },
  {
    "languageCode": "fil_PH",
    "languageName": "Filipino",
    "languageNativeName": "Mga Filipino",
    "translationRatio": 0.2760050685404907
  },
  {
    "languageCode": "fr_FR",
    "languageName": "French",
    "languageNativeName": "Français",
    "translationRatio": 0.9313443151710632
  },
  {
    "languageCode": "ha_HG",
    "languageName": "Hausa",
    "languageNativeName": "هَوُسَ",
    "translationRatio": 0.031793572169104944
  },
  {
    "languageCode": "he_IL",
    "languageName": "Hebrew",
    "languageNativeName": "עברית",
    "translationRatio": 0.18108512844142377
  },
  {
    "languageCode": "hi_IN",
    "languageName": "Hindi",
    "languageNativeName": "हिन्दी",
    "translationRatio": 0.1496371385785048
  },
  {
    "languageCode": "hu_HU",
    "languageName": "Hungarian",
    "languageNativeName": "magyar",
    "translationRatio": 0.38198364243750715
  },
  {
    "languageCode": "id_ID",
    "languageName": "Indonesian",
    "languageNativeName": "Bahasa Indonesia",
    "translationRatio": 0.5014399262757747
  },
  {
    "languageCode": "ig_NG",
    "languageName": "Igbo",
    "languageNativeName": "Asụsụ Igbo",
    "translationRatio": 0.03064163114848517
  },
  {
    "languageCode": "it_IT",
    "languageName": "Italian",
    "languageNativeName": "Italiano",
    "translationRatio": 0.9798410321391545
  },
  {
    "languageCode": "ja_JP",
    "languageName": "Japanese",
    "languageNativeName": "日本語",
    "translationRatio": 0.9850247667319433
  },
  {
    "languageCode": "ka_GE",
    "languageName": "Georgian",
    "languageNativeName": "ქართული",
    "translationRatio": 0.058748992051606996
  },
  {
    "languageCode": "km_KH",
    "languageName": "Khmer",
    "languageNativeName": "ខេមរភាសា",
    "translationRatio": 0.030987213454671148
  },
  {
    "languageCode": "ko_KR",
    "languageName": "Korean",
    "languageNativeName": "한국어",
    "translationRatio": 0.8810044925699804
  },
  {
    "languageCode": "lt_LT",
    "languageName": "Lithuanian",
    "languageNativeName": "lietuvių kalba",
    "translationRatio": 0.25676765349614095
  },
  {
    "languageCode": "lv_LV",
    "languageName": "Latvian",
    "languageNativeName": "latviešu valoda",
    "translationRatio": 0.026609837576316075
  },
  {
    "languageCode": "mr_IN",
    "languageName": "Marathi",
    "languageNativeName": "मराठी",
    "translationRatio": 0.03882041239488543
  },
  {
    "languageCode": "ms_MY",
    "languageName": "Malay",
    "languageNativeName": "Bahasa Melayu",
    "translationRatio": 0.05598433360211952
  },
  {
    "languageCode": "my_MM",
    "languageName": "Burmese",
    "languageNativeName": "ဗမာစာ",
    "translationRatio": 0.028699861687413564
  },
  {
    "languageCode": "nl_NL",
    "languageName": "Dutch",
    "languageNativeName": "Nederlands",
    "translationRatio": 0.477364358944822
  },
  {
    "languageCode": "no_NO",
    "languageName": "Norwegian",
    "languageNativeName": "Norsk",
    "translationRatio": 0.38947125907153557
  },
  {
    "languageCode": "pl_PL",
    "languageName": "Polish",
    "languageNativeName": "Polski",
    "translationRatio": 0.9888261720999885
  },
  {
    "languageCode": "pseudo_LOCALE",
    "languageName": "for development only",
    "languageNativeName": "Pseudolocalization",
    "translationRatio": 0.9957678355501813
  },
  {
    "languageCode": "pt_BR",
    "languageName": "Brazilian Portuguese",
    "languageNativeName": "Português brasileiro",
    "translationRatio": 0.864762124179242
  },
  {
    "languageCode": "pt_PT",
    "languageName": "Portuguese",
    "languageNativeName": "Português",
    "translationRatio": 0.33751871904158504
  },
  {
    "languageCode": "ro_RO",
    "languageName": "Romanian",
    "languageNativeName": "Română",
    "translationRatio": 0.10091003340628957
  },
  {
    "languageCode": "ru_RU",
    "languageName": "Russian",
    "languageNativeName": "Русский",
    "translationRatio": 0.990899665937104
  },
  {
    "languageCode": "si_LK",
    "languageName": "Sinhala",
    "languageNativeName": "සිංහල",
    "translationRatio": 0.6199746572975464
  },
  {
    "languageCode": "sk_SK",
    "languageName": "Slovak",
    "languageNativeName": "slovenčina",
    "translationRatio": 0.16668586568367705
  },
  {
    "languageCode": "sl_SI",
    "languageName": "Slovenian",
    "languageNativeName": "slovenščina",
    "translationRatio": 0.6796452021656492
  },
  {
    "languageCode": "sq_AL",
    "languageName": "Albanian",
    "languageNativeName": "Shqip",
    "translationRatio": 0.1532964499769479
  },
  {
    "languageCode": "sr_CS",
    "languageName": "Serbian (Latin)",
    "languageNativeName": "srpski",
    "translationRatio": 0.13788734016818338
  },
  {
    "languageCode": "sr_SP",
    "languageName": "Serbian",
    "languageNativeName": "српски језик",
    "translationRatio": 0.28925239027761773
  },
  {
    "languageCode": "sv_SE",
    "languageName": "Swedish",
    "languageNativeName": "Svenska",
    "translationRatio": 0.1855776984218408
  },
  {
    "languageCode": "sw_KE",
    "languageName": "Swahili",
    "languageNativeName": "Kiswahili",
    "translationRatio": 0.02672503167837803
  },
  {
    "languageCode": "th_TH",
    "languageName": "Thai",
    "languageNativeName": "ไทย",
    "translationRatio": 0.28095841492915563
  },
  {
    "languageCode": "tr_TR",
    "languageName": "Turkish",
    "languageNativeName": "Türkçe",
    "translationRatio": 0.964059440156664
  },
  {
    "languageCode": "uk_UA",
    "languageName": "Ukrainian",
    "languageNativeName": "Українська",
    "translationRatio": 0.9888261720999885
  },
  {
    "languageCode": "ur_PK",
    "languageName": "Urdu",
    "languageNativeName": "اردو",
    "translationRatio": 0.03640133625158393
  },
  {
    "languageCode": "uz_UZ",
    "languageName": "Uzbek",
    "languageNativeName": "Ўзбек",
    "translationRatio": 0.03064163114848517
  },
  {
    "languageCode": "vi_VN",
    "languageName": "Vietnamese",
    "languageNativeName": "Tiếng Việt",
    "translationRatio": 0.29915908305494754
  },
  {
    "languageCode": "yo_NG",
    "languageName": "Yoruba",
    "languageNativeName": "Yorùbá",
    "translationRatio": 0.27047575164151594
  },
  {
    "languageCode": "zh_CN",
    "languageName": "Chinese Simplified",
    "languageNativeName": "简化字",
    "translationRatio": 0.9905540836309181
  },
  {
    "languageCode": "zh_TW",
    "languageName": "Chinese Traditional",
    "languageNativeName": "正體字",
    "translationRatio": 0.9309987328648773
  }
];
